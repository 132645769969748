@use '@angular/material' as mat;
@import '~@angular/material/theming';

$toolbar-breakpoint: 600px;

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

/*
https://material-ui.com/customization/breakpoints/
$grid-breakpoints: (
  xs: 0,
  sm: 600x,
  md: 960px,
  lg: 1280px,
  xl: 1920px
);

$container-max-widths: (
  sm: 580px,
  md: 940px,
  lg: 1220px,
  xl: 1830px
);
*/
$success-colors: mat.define-palette(mat.$green-palette, 400);
$warning-colors: mat.define-palette(mat.$amber-palette, 400);

$bannerHeight: 70px;
$topNavigationHeight: 297px;
