@use '@angular/material' as mat;

.default-theme {
  @include mat.all-component-colors($anms-theme);
  @include mat.all-component-typographies($anms-theme);
  @include custom-components-theme($anms-theme);
  @include mat-custom-styles($anms-theme);

  .mat-list-item {
    &:hover,
    &:focus {
      .sidebar-icon {
        --icon-dark: #283593 !important;
        --icon-light: #c8cbe3 !important;
      }
    }
    &.active-link {
      .sidebar-icon {
        --icon-dark: #283593 !important;
        --icon-light: #c8cbe3 !important;
      }
    }
  }
}

.light-theme {
  @include mat.all-component-colors($anms-light-theme);
  @include custom-components-theme($anms-light-theme);
  @include mat-custom-styles($anms-light-theme);
  $accent: map-get($anms-light-theme, accent);

  .active-link {
    .sidebar-icon {
      --icon-dark: #bcaaa4 !important;
      --icon-light: #d6ccc9 !important;
    }
  }

  .mat-list-item {
    &:hover,
    &:focus {
      .sidebar-icon {
        --icon-dark: #bcaaa4 !important;
        --icon-light: #d6ccc9 !important;
      }
    }
  }

  .mat-toolbar {
    .branding {
      .title {
        p {
          color: $black;
        }
      }
    }
    #main-nav {
      li,
      span,
      button,
      a {
        color: $black;
      }
    }
    .lang-select {
      span.material-icons {
        color: $black !important;
      }
    }
    .mat-icon-button {
      fa-icon {
        color: $black;
        font-size: 24px;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($accent, 800);
  }
}

.black-theme {
  @include mat.all-component-colors($anms-black-theme);
  @include custom-components-theme($anms-black-theme);
  @include mat-custom-styles($anms-black-theme);
  $accent: map-get($anms-black-theme, accent);

  .sidebar-icon {
    --icon-dark: #616161 !important;
    --icon-light: #212121 !important;
  }

  .active-link {
    .sidebar-icon {
      --icon-dark: #455a64 !important;
      --icon-light: #78909c !important;
    }
  }

  .mat-list-item {
    &:hover,
    &:focus {
      .sidebar-icon {
        --icon-dark: #455a64 !important;
        --icon-light: #78909c !important;
      }
    }
  }

  .mat-toolbar {
    //background-color: #000;
    a,
    span,
    p,
    li,
    fa-icon {
      color: $white !important;
    }
  }
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($accent, 50);
  }
}

.nature-theme {
  @include mat.all-component-colors($anms-nature-theme);
  @include custom-components-theme($anms-nature-theme);
  @include mat-custom-styles($anms-nature-theme);

  .active-link {
    .sidebar-icon {
      --icon-dark: #9575cd !important;
      --icon-light: rgba(149, 117, 205, 0.54) !important;
    }
  }

  .mat-list-item {
    &:hover,
    &:focus {
      .sidebar-icon {
        --icon-dark: #9575cd !important;
        --icon-light: rgba(149, 117, 205, 0.54) !important;
      }
    }
  }
}
.kvasar-theme {
  @include mat.all-component-colors($kvasar-theme);
  @include mat.all-component-typographies($kvasar-theme);
  @include custom-components-theme($kvasar-theme);
  @include mat-custom-styles($kvasar-theme);
}
/*
.team-selector-feature-form {
  anms-teams-select {
    div {
      .mat-form-field {
        .mat-form-field-wrapper {
          width: 105% !important;
        }
      }
    }
  }
}*/
/*
.resize-card {
  resize: horizontal !important;
}
  
.subtitle-epics-info {
  color: black !important;
}
 
.fr-action-buttons {
  position: relative !important;
  right: 20px !important;
  width: 100% !important;
  height: 100% !important;
  button {
    width: 60px !important;
    height: 100% !important;
    border-radius: 5px;
  }
}
   */
.img-attach-class {
  anms-overlaycomponent {
    background-color: #1b2638 !important;
  }
}
