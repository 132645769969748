@import '../../../styles-variables.scss';

// Create a config with the default typography levels.

@mixin anms-input-editor-component-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .box {
    .output {
      color: mat.get-color-from-palette($primary, 700);
    }
  }

  /*
  :host {
    display: flex;
    align-items: center;
    cursor: default;

    .box {
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      background-color: mat.get-color-from-palette(
        $background
      ); // Dynamic background color
      border: solid 1px transparent;
      min-width: 80px;
      transition: background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;

      &:hover {
        background-color: mat.get-color-from-palette(
          $primary,
          100
        ); // Dynamic hover background
        border: solid 1px mat.get-color-from-palette($primary, 200); // Dynamic hover border
      }

      &:focus {
        height: 100% !important;
        z-index: 100;
      }

      .editable-content {
        display: none;
        caret-color: mat.get-color-from-palette(
          $primary
        ); // Dynamic caret color using primary
        cursor: text;
        color: mat.get-color-from-palette(
          $foreground,
          text
        ); // Dynamic text color
        min-width: 3rem;
        font-size: 16px;
        transition: color 0.2s ease-in-out;
        padding: 15px;
      }

      .output {
        padding: 15px;
        font-size: 16px;
        color: mat.get-color-from-palette($primary, 700);
      }

      .controls {
        &.controls-default {
          position: absolute;
          left: 90%;
          top: 110%;
          display: flex;
        }

        &.controls-left {
          position: absolute;
          left: -60px;
          top: 30%;
          display: flex;
        }

        &.controls-right {
          position: absolute;
          right: -60px;
          top: 30%;
          display: flex;
        }

        &.controls-above {
          position: absolute;
          right: 40%;
          top: 0%;
          display: flex;
        }

        &.controls-below {
          position: absolute;
          right: 10%;
          bottom: -55px;
          display: flex;
        }

        &.controls-before {
          position: absolute;
          left: -60px;
          top: 0;
        }

        &.controls-after {
          position: absolute;
          right: -60px;
          top: 0;
        }

        .material-icons {
          font-size: 18px;
          cursor: pointer;
          background: mat.get-color-from-palette(
            $background
          ); // Dynamic background color
          width: 22px;
          height: 22px;
          border-radius: 4px;
          box-shadow: 0px 2px 6px
            mat.get-color-from-palette($background, shadow); // Dynamic shadow color
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
          .material-icons {
            font-size: 24px;
            width: 40px; // Increase the width to provide more touch area
            height: 40px; // Increase the height to provide more touch area
            margin-right: 10px; // Add margin between icons for spacing
          }
        }

        span {
          z-index: 1001;
        }
      }

      .error-length {
        font-size: 24px;
        position: absolute;
        color: mat.get-color-from-palette($theme, error); // Dynamic error color
        bottom: -26px;
        min-width: 240px;
        width: 100%;
      }
    }

    &.edit-mode {
      .box {
        background-color: mat.get-color-from-palette(
          $background,
          edit-background
        ); // Dynamic edit background color
        border: solid 1px mat.get-color-from-palette($primary); // Dynamic edit border color using primary

        .output {
          display: none;
        }

        .editable-content {
          visibility: visible;
          display: block;
        }
      }
    }
  } */
}
