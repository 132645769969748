@use '@angular/material' as mat;

@mixin anms-teams-art-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .team-art-list-title {
    color: mat.get-color-from-palette($foreground, text);
  }
}
