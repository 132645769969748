// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-ngrx-material-starter-primary: mat.define-palette(mat.$indigo-palette);
$angular-ngrx-material-starter-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$angular-ngrx-material-starter-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-ngrx-material-starter-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-ngrx-material-starter-primary,
      accent: $angular-ngrx-material-starter-accent,
      warn: $angular-ngrx-material-starter-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-ngrx-material-starter-theme);

.app-loading {
  .logo {
    width: 64px;
    height: 64px;
    background: url(./assets/logo.png) center center no-repeat;
    background-size: cover;
  }
}
