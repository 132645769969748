@use '@angular/material' as mat;

@mixin anms-kanban-column-container-component-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  //$lighter: map-get($theme, lighter);

  .kanban-column {
    max-height: unset;
    background: mat.get-color-from-palette($primary, 300);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }

  /* A class that is added to cdkDropList while the user is dragging an item. */
  .kanban-column.cdk-drop-list-dragging {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  /* Animate an item that has been dropped. */
  .kanban-column.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  }

  .kanban-column.cdk-drop-list-dragging .kanban-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  anms-kanban-column {
    > div {
      height: 100%;
    }
  }

  @media (min-width: map-get($grid-breakpoints, xs)) {
    .kanban-column {
      min-height: 60px;
      max-width: 100%;
      min-width: 270px;
      height: calc(100% - 100px);
    }
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    .kanban-column {
      min-height: 80px;
      max-width: 100%;
      min-width: 280px;
      height: calc(100% - 100px);
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    .kanban-column {
      min-height: 250px;
      max-width: 270px;
      width: 100%;
      height: calc(100% - 100px);
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    .kanban-column {
      min-height: 500px;
      max-width: 270px;
      width: 100%;
      height: calc(100% - 100px);
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    .kanban-column {
      min-height: 500px;
      max-width: 270px;
      width: 100%;
      height: calc(100% - 100px);
    }
  }
}
