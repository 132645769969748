/*@mixin anms-teams-select-theme($theme) {
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  @if $color != null {
    @include candy-carousel-color($color);
  }
  @if $typography != null {
    @include team-select-typography($typography);
  }
}
*/

@mixin anms-teams-select($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .anms-teams-select {
    &.mat-primary {
      color: mat.get-color-from-palette($primary);
      background-color: mat.get-color-from-palette($primary, 0.15);
    }

    &.mat-accent {
      color: mat.get-color-from-palette($accent);
      background-color: mat.get-color-from-palette($accent, 0.15);
    }

    &.mat-warn {
      color: mat.get-color-from-palette($warn);
      background-color: mat.get-color-from-palette($warn, 0.15);
    }
  }
}
@mixin team-select-typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  .my-carousel {
    font-family: mat.font-family($typography-config);
  }
}
