@use '@angular/material' as mat;

@mixin anms-app-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .error-notification-overlay {
    color: mat.get-color-from-palette($warn, default-contrast);
    background-color: mat.get-color-from-palette($warn);
  }

  .info-notification-overlay {
    color: mat.get-color-from-palette($accent, default-contrast);
    background-color: mat.get-color-from-palette($accent);
  }

  .success-notification-overlay {
    color: mat.get-color-from-palette($success-colors, default-contrast);
    background-color: mat.get-color-from-palette($success-colors);
  }

  .warning-notification-overlay {
    color: mat.get-color-from-palette($warning-colors, default-contrast);
    background-color: mat.get-color-from-palette($warning-colors);
  }
}
