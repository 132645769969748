@import '~@angular/material/theming';

@mixin anms-epic-form-overlay-component-theme($theme) {
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  /*
  .overlay {
    width: 270px;
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    .overlay {
      width: 300px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, md)) {
    .overlay {
      width: 300px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    .overlay {
      width: 450px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    .overlay {
      width: 700px;
    }
  }
  */
  .epic-accordion-wrapper {
    padding-right: 10px;
    padding-bottom: 0.5rem;
    .mat-expansion-panel {
      box-shadow: none !important;
      background-color: mat.get-color-from-palette($foreground);

      .mat-expansion-panel-header {
        background: mat.get-color-from-palette($accent, 200);
        .mat-expansion-panel-header-title {
          color: mat.get-color-from-palette($primary);
          min-width: fit-content;
        }

        .mat-expansion-panel-header-description {
          color: mat.get-color-from-palette($primary);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .mat-expansion-panel-content {
        background-color: rgba(0, 0, 0, 0.04);
      }

      textarea {
        color: mat.get-color-from-palette($foreground, secondary-text);
        padding-top: 1rem;
      }
    }
  }
}
