@mixin title-card-component-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);

  .column-title {
    border-radius: 4px 4px 0px 0px;
    background: #e4e4e4;
    padding: 12px 15px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    h3 {
      //color: #212121;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.75px;
      margin-bottom: 0;
      fa-icon {
        margin-right: 10px;
      }
    }
    .column-issues {
      color: mat.get-color-from-palette($primary, 700);
    }
    .custom-icon {
      // color: #b9b9b9;
      color: mat.get-color-from-palette($primary, 700);
    }
  }
}
