//CUSTOM THEME STYLING (dynamically applied to every theme)
@use '@angular/material' as mat;
@mixin mat-custom-styles($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  button.add.mat-fab {
    &:hover {
      background-color: mat.get-color-from-palette($accent, A700);
    }
  }
  html,
  body {
    height: 100%;
    font-size: 16px;
  }
  body {
    margin: 0;
    // font-family: Roboto, 'Helvetica Neue', sans-serif;
    overflow-y: hidden;
  }
  .mat-toolbar {
    .branding {
      .title {
        p {
          color: mat.get-color-from-palette($background, card);
        }
      }
    }
    #main-nav {
      li,
      span,
      button,
      a {
        color: mat.get-color-from-palette($background, card);
      }
    }
    .mat-icon-button {
      fa-icon {
        color: mat.get-color-from-palette($background, card);
        font-size: 24px;
      }
      &:hover {
        fa-icon {
          transform: scale(1.05);
        }
      }
    }
  }
  /*
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    &.dark-backdrop {
      background: #000;
      opacity: 0.35 !important;
    }
  }
  */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  // https://css-tricks.com/custom-scrollbars-in-webkit/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: rgba(5, 5, 43, 0.35);
  }
  .footer {
    background-color: mat.get-color-from-palette($primary, 500);
  }

  /*Titles*/
  h1 {
    color: mat.get-color-from-palette($foreground, text);
  }
  h2 {
    font-weight: 400 !important;
    font-size: 24px !important;
    padding: 0 !important;
    color: mat.get-color-from-palette($foreground, secondary-text);
    &.mat-subheader {
      font-size: 18px !important;
      margin-bottom: 6px;
      font-weight: 500 !important;
      letter-spacing: 0.49px;
    }
  }
  mat-placeholder {
    color: mat.get-color-from-palette($foreground, text);
  }
  /*Buttons*/
  button.add.mat-fab {
    border-radius: 40px;
    height: 36px;
    margin-bottom: 20px;
    width: fit-content;
    padding: 0 20px;

    &:hover {
      //background: mat-color($accent, 700);
    }
    span.mat-button-wrapper {
      letter-spacing: 0.89px;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;

      fa-icon {
        margin-right: 5px;
      }
    }
  }
  .mat-input-element {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 16px;
    }
  }
  .col-lg-4 {
    button.add.mat-fab {
      width: calc(100% - 17.5px);
    }
  }
  button.fixed-top,
  .row-fixed-top {
    position: fixed;
    top: 100px;
    right: 20px;
  }
  .row-fixed-top {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /*Iconography*/
  .material-icons {
    //color: mat-color($background, card);
    background: none !important;
    transition: 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  .custom-icon {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  .art-icon {
    background-image: url(./assets/icons/icon-art-old.svg);
    background-size: 28px;
  }
  .team-icon {
    background-image: url(./assets/icons/icon-team.svg);
    background-size: 28px;
  }
  .epic-icon {
    background-image: url(./assets/icons/icon-epic.svg);
    background-size: 28px;
  }
  .feature-icon {
    background-image: url(./assets/icons/icon-feature.svg);
    background-size: 28px;
  }
  .story-icon {
    background-image: url(./assets/icons/icon-story.svg);
    background-size: 28px;
  }
  .va-icon {
    background-image: url(./assets/icons/icon-value-stream.svg);
    display: inline-block;
    width: 32px;
    height: 20px;
    vertical-align: text-bottom;
  }
  .solution-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-icon {
      width: 16px !important;
      height: auto;
    }
  }
  /*Menu & Submenu*/
  .mat-menu-panel {
    //background: map-get($theme)
    min-width: 195px !important;
    padding: 15px 15px 0 15px;
    margin-top: 6px;
    .mat-menu-content {
      padding: 0 !important;
      p:not(.recent-label) {
        margin-bottom: 0;
      }
      .mat-menu-item {
        padding: 0;
        padding-bottom: 8px;
        height: fit-content;
        line-height: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover,
        &:active,
        &:focus {
          background: transparent;
          color: mat.get-color-from-palette($primary);
        }
        a {
          color: mat.get-color-from-palette($foreground, text);
          border: none;
          &:hover,
          &:active,
          &:focus {
            background: transparent;
            color: mat.get-color-from-palette($accent);
          }
        }
        .mat-icon {
          margin-right: 5px;
          width: 32px;
          height: 32px;
          text-align: center;
          background: mat.get-color-from-palette($primary, 100);
          border-radius: 50%;
          color: mat.get-color-from-palette($primary, 500);
          svg {
            max-height: 19px;
            max-width: 19px;
            vertical-align: middle !important;
            margin-top: 6px;
          }
          &[data-mat-icon-name='portfolio'] {
            svg {
              max-width: 15px;
            }
          }
          &[data-mat-icon-name='solution'] {
            svg {
              max-width: 16px;
              max-height: 21px;
            }
          }
        }
      }
    }
  }
  .view-all-label {
    margin: 8px -15px 0 !important;
    padding: 12px 16px;
    background: mat.get-color-from-palette($primary, 100);
    border-top: solid 1px #b9b9b921;
    a {
      border: none;
      color: mat.get-color-from-palette($primary, 500) !important;
      font-size: 14px;
      font-weight: 400;
      &:hover {
        color: mat.get-color-from-palette($primary, 300);
      }
    }
  }
  /*Page Intro: title & breadcrumb*/

  .page-intro {
    margin: 0;
    margin-bottom: 1rem;
    div {
      padding: 0;
    }
    .page-title {
      h2 {
        font-size: 28px !important;
        font-weight: 500 !important;
        margin-bottom: 3px;
      }
    }
    .breadcrumb {
      .breadcrumb-list {
        padding-inline-start: 0px;
        display: flex;
        list-style: none;
        margin-bottom: 0rem;
        flex-wrap: nowrap;
        .breadcrumb-list-item {
          a {
            color: #a3a3a3;
            font-size: 14px;
            white-space: nowrap;
            &:after {
              content: '>';
              display: inline-block;
              vertical-align: middle;
              margin-right: 0px;
              white-space: nowrap;
            }
            &.current {
              color: #434343;
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  /*
  .page-content {
    margin: 0;
    margin-bottom: 1rem;
  }
  */
  .container-fluid.px-0,
  .container-fluid {
    height: 100%;
    background-color: transparent;
  }
  main {
    background-color: mat.get-color-from-palette($background, app-bar);
    /*> div,
    .column-title,
    .col-item,
    .card-top,
    .collapse-column {
      background-color: mat.get-color-from-palette($background, app-bar);
    }*/
  }

  /*Filter ng-multiselect styling*/
  .multiselect-dropdown {
    .dropdown-btn {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
      background: #f2f2f2;
      min-height: 42px;
      line-height: 2 !important;
      .selected-item {
        padding: 2.5px 10px !important;
        background: mat.get-color-from-palette($primary, 100) !important;
        border: 1px solid mat.get-color-from-palette($primary, 100) !important;
        border-radius: 4px !important;
        max-width: unset !important;
        line-height: 1.5;
        a,
        span {
          color: mat.get-color-from-palette($primary) !important;
          font-size: 14px;
        }
        a {
          padding-left: 15px !important;
        }
        &:hover {
          box-shadow: none !important;
        }
      }

      > span:not(.selected-item-container) {
        span:not(.dropdown-multiselect__caret) {
          display: flex;
          align-items: center;
          padding-right: 25px !important;
          line-height: 2;
        }
      }
    }
    .dropdown-list {
      margin-top: 0 !important;
      ul {
        &::-webkit-scrollbar {
          width: 8px;
        }
        li {
          div {
            color: mat.get-color-from-palette(
              $foreground,
              secondary-text
            ) !important;
          }
        }
      }
      .multiselect-item-checkbox input[type='checkbox']:checked + div:before {
        background-color: mat.get-color-from-palette($primary) !important;
        border-color: mat.get-color-from-palette($primary);
        border-radius: 2px;
      }
      .multiselect-item-checkbox input[type='checkbox'] + div:before {
        border-color: mat.get-color-from-palette($primary) !important;
        border-radius: 2px;
      }
    }
  }
  /*Sidebar Styling*/

  /*
  .sidebar-toggle-button {
    width: 24px;
    height: 24px;
    background: #fff !important;
    // display: ;
    text-align: center;
    line-height: 23px;
    border-radius: 50%;
    border: solid 1px #a4a4a4;
    box-shadow: 2px 0 8px rgb(0 0 0 / 10%);
    min-width: unset;
    padding: 0;
    z-index: 9;

    span {
      font-size: 18px;
      line-height: 1.3;
    }

    &:hover {
      background: #616baf !important;
      border-color: #616baf;
      span {
        color: #fff;
      }
    }
  }
*/

  mat-sidenav {
    // width: 70px;
    // visibility: visible !important;
    //transform: none !important;
    //overflow-x: hidden;
    //z-index: 100;
    // transition: 0.3s ease-in-out;
    //box-shadow: none !important;
    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 0px;
    }
    > div {
      overflow-x: hidden;
    }
    .sidenav-heading {
      margin: 0px auto 15px 20px;
      background-color: mat.get-color-from-palette($primary, 50);
      border-radius: 4px;
      width: 32px;
      margin-bottom: 32px;
      h1,
      p {
        margin-bottom: 0;
      }
      h1.main-heading {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        color: mat.get-color-from-palette($primary);
        text-align: center !important;
      }
    }
    .mat-divider {
      border-top-color: mat.get-color-from-palette($primary, 200);
    }

    .mat-nav-list,
    .mat-list {
      width: 100%;
      padding-top: 0;
      .mat-list-item {
        //padding-top: 7.5px;
        -webkit-tap-highlight-color: #f3f3f7;
        -moz-tap-highlight-color: #f3f3f7;
        height: 48px;
        font-size: 14px;
        @media (max-width: map-get($grid-breakpoints, lg)) {
          height: 52px;
          font-size: 16px;
        }

        transition: 0.2s ease-in-out;
        font-weight: 500;
        // margin-bottom: 7.5px;
        background: none;
        // display: inline-block;

        .sidebar-icon {
          --icon-dark: #525252;
          --icon-light: #a8a8a8;
          margin-right: 12px;
        }

        &:hover {
          color: mat.get-color-from-palette($primary);
          background-color: mat-color($background, hover);
        }

        &:focus {
          color: mat.get-color-from-palette($primary);
          background-color: mat-color($background, focused-button);
        }
        &.active-link {
          color: mat.get-color-from-palette($primary);
          background-color: mat-color($background, selected-button);
        }
        span {
          opacity: 0;
          visibility: visible;
          // transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition: ease-in-out 0.55s;
          width: 185px !important;
          //position: absolute;
          // margin-left: 5px;
          // left: 60px;
          &.mat-expansion-indicator {
            opacity: 0;
            visibility: hidden;
            transition: 0.9s;
            // width: 225px;
            // width: 125px;

            // transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          }
        }
        p {
          margin-bottom: 0;
        }
        .mat-ripple {
          display: none;
        }
        .mat-list-item-content {
          display: flex; /* Use flexbox */
          align-items: center; /* Center items vertically */
          padding-left: 20px;
        }
        > div {
          display: block;
        }
      }
    }
    .sidebar-dropdown {
      margin-bottom: 15px;
      .mat-expansion-panel {
        box-shadow: none;
      }
      .mat-expansion-panel-header {
        padding-left: 0;
        padding-right: 24px;
        align-items: top;
        background: none !important;
        @media (max-width: map-get($grid-breakpoints, sm)) {
          height: 44px;
        }
        height: 48px !important;
        .mat-content {
          height: 100%;
        }
        .mat-expansion-panel-header-description {
          flex-direction: column;
          margin-right: 0;
          display: block;

          .mat-list-item {
            //font-weight: 400;
            // margin-bottom: 0;
            height: 48px;
            font-size: 14px;
            @media (max-width: map-get($grid-breakpoints, lg)) {
              height: 52px;
              font-size: 16px;
            }

            // transition: 0.2s ease-in-out;
            font-weight: 500;
            // margin-bottom: 7.5px;
            background: none;
          }
        }
        .mat-expansion-indicator {
          visibility: visible;
          width: auto;
          position: absolute;
          right: 24px;
          top: 10px;
        }
      }
      .mat-expansion-panel-body {
        padding-right: 0 !important;
        padding-left: 36px;
        padding-bottom: 0 !important;
      }
    }
    anms-footer {
      opacity: 0;
      visibility: hidden;
      // width: 275px !important;
      transition: 0.5s;
      > div {
        width: 270px !important;
      }
    }

    &.mat-drawer-opened {
      width: 270px;
      box-shadow: 0px 5px 28px rgb(0 0 0 / 10%) !important;
      .mat-nav-list,
      .mat-list {
        width: 100%;

        .mat-list-item {
          min-width: 255px !important;
          span {
            opacity: 1;
            transition: ease-in-out 0.3s;
            width: 185px !important;
            //position: absolute;
            //margin-left: 0px;
            //left: 60px;
          }
          > div {
            //display: flex;
            flex-direction: row;
          }
        }
      }
      /*
      .sidebar-dropdown {
        .mat-expansion-panel-header {
          padding-left: 0;
          padding-right: 24px;
          align-items: top;
          .mat-expansion-panel-header-description {
            display: block;
          }
          .mat-expansion-indicator {
            visibility: visible;
            width: auto;
          }
        }
      }*/
      .sidebar-icon {
        margin-right: 12px;
      }
      anms-footer {
        opacity: 1;
        visibility: visible;
        transition: 0.5s;
        // width: 275px !important;
        > div {
          width: 270px !important;
        }
      }
    }
  }

  /*Card styling*/
  .solution-card mat-icon svg {
    width: 16px;
  }
  .mat-card {
    //padding: 0;
    .cdk-drag-handle {
      z-index: 40;
      span.material-icons {
        font-size: 20px;
      }
    }
    .extra-info {
      margin-bottom: 5px !important;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.89px;
      font-weight: 300;
      display: flex;
      text-transform: capitalize;
      color: mat.get-color-from-palette($primary);
      span {
        font-weight: 400;
        margin-left: 2px;
      }
    }
    .mat-card-header {
      align-items: flex-start;
      flex-direction: column;
      .mat-card-header-text {
        margin: 0;
      }
      .mat-card-title {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .mat-card-avatar {
        background-color: mat.get-color-from-palette($primary, 100);
        position: relative;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background-size: 16px;
        svg {
          display: flex;
          margin: 0 auto;
        }
      }
      span.total-teams {
        background: mat.get-color-from-palette($primary, 500);
        color: #fff;
        font-size: 11px;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 32px;
        left: 10px;
        line-height: 22px;
      }
    }
    .description {
      margin: 0px;
      font-size: 11px;
      line-height: 12px;
      color: #6c6c6c;
      letter-spacing: 0.89px;
      font-weight: 300;
      margin-top: 5px;
    }
    .mat-card-actions {
      margin: 0 auto;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      width: 100%;
      button.mat-button {
        min-width: unset;
        padding: 0;
        margin-right: 0px;
        line-height: 18px;
        margin-bottom: 5px;
        span {
          color: mat.get-color-from-palette($primary);
          font-size: 12px;
          letter-spacing: 0.89px;
          &.material-icons {
            vertical-align: text-top;
            padding: 0;
          }
          &.mat-ripple {
            display: none;
          }
        }
        &:hover {
          .mat-button-focus-overlay {
            opacity: 0 !important;
          }
          .material-icons,
          fa-icon {
            transform: scale(1.1);
          }
        }
      }
    }
    .mat-card-content {
      margin-top: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .info {
        display: flex;
        flex-wrap: wrap;
        p {
          margin-right: 5px;
          line-height: 10px;
        }
      }

      .team-icon {
        background-image: url(./assets/icons/icon-team.svg);
        background-size: contain;
        display: inline-block;
        vertical-align: text-bottom;
        width: 22px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .card-highlights {
      .art svg {
        height: 16px;
        width: 25px;
        margin-left: 5px;
      }
    }
    &.cdk-drag-disabled {
      .drag {
        display: none;
      }
    }
    &:hover {
      box-shadow: 0px 2px 3.5px #00000035;
    }
    &.internal {
      mat-card-header {
        .solution-icon {
          background-color: transparent;
        }
        .type-label {
          background-color: transparent;
          color: currentColor;
        }
      }
    }
    &.solution {
      margin-bottom: 10px;
      width: 100%;
      mat-card-header {
        position: relative;
        margin-bottom: 5px;
        .solution-icon {
          width: 32px;
          height: 32px;
          z-index: 20;
          background-image: none;
          z-index: 20;
          background-image: none;
          display: flex;
          align-items: center;
          justify-content: center;
          .custom-icon {
            width: 16px !important;
            height: auto;
          }
        }
        .type-label {
          background-color: mat.get-color-from-palette($primary, 400);
          text-transform: uppercase;
          letter-spacing: 0.89px;
          color: #fff;
          font-size: 10px;
          font-weight: 500;
          position: absolute;
          left: 22px;
          top: 6px;
          z-index: 10;
          min-width: 80px;
          padding: 0px 10px;
          border-radius: 3px;
          text-align: center;
        }
      }
      mat-card-content {
        display: flex;
        flex-direction: column;
        .title {
          margin-bottom: 0px;
          mat-card-title {
            font-size: 14px;
            margin-bottom: 0px;
            line-height: 14px;
          }
          .code {
            font-weight: 400;
            font-size: 11px;
            text-transform: uppercase;
            line-height: 11px;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          p {
            font-size: 12px;
            text-transform: capitalize;
            display: flex;
            flex-direction: row;
            align-items: center;
            fa-icon {
              font-size: 16px;
              color: #adadad;
            }
          }
        }
        .description {
          max-height: 22px;
          overflow: hidden;
          width: 90% !important;
          white-space: nowrap;
          text-overflow: ellipsis;
          .mat-icon {
            height: 24px !important;
            width: 35px !important;
          }
        }
      }
      mat-card-actions {
        .buttons {
          position: absolute;
          bottom: 10px;
          right: 11px;

          button {
            fa-icon {
              font-size: 14px;
              color: #333;
              &:hover {
                color: mat.get-color-from-palette($primary, 400);
              }
            }
          }
        }
      }
      &.internal {
        mat-card-header {
          .solution-icon {
            background-color: transparent;
          }
          .type-label {
            background-color: transparent;
            color: currentColor;
          }
        }
      }
      &.external {
        border-left: solid 5px;
        mat-card-header {
          .type-label {
            background-color: currentColor;
            > span {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  /*Drag & Drop*/
  .example-handle {
    position: absolute;
    top: 12px;
    right: 6px;
    color: #ccc;
    cursor: move;
    width: 24px;
    height: 24px;
  }
  .list.cdk-drop-list {
    padding: 5px 0 !important;
    overflow-y: auto;
    mat-card {
      margin-right: 7.5px;
      min-height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .cdk-drag-placeholder {
      background: #7070707e;
    }
  }

  .cdk-drag-placeholder {
    border: 1px dashed #707070 !important;
    border-radius: 4px;
    width: 100%;
    background-color: transparent;
  }
  .cdk-drop-list-dragging {
    border: none !important;
    .cdk-drag-preview {
      border: 1px solid mat.get-color-from-palette($accent, 400);
      width: 100% !important;
    }
  }
  .cdk-drag-preview {
    // border: 1px dashed mat-color($accent, 400);
    .cdk-drag-handle {
      span {
        color: mat.get-color-from-palette($accent, 400);
      }
    }
  }
  .cdk-drop-list-receiving {
    width: 100%;
  }
  .solutions-placeholder {
    min-height: 200px;
  }
  .solutions-drop-placeholder {
    background: #ccc;
    margin: 0px;
    box-sizing: border-box;
    border: dotted 3px #999;
    min-height: 100px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    width: 100% !important;
  }

  /*Main section: element list*/

  .custom-list {
    overflow-y: auto;
    .list-item {
      margin-bottom: 15px;
      margin-right: 7.5px;

      mat-expansion-panel {
        background: transparent !important;

        mat-expansion-panel-header {
          height: auto !important;
          padding: 0 !important;
          background: mat.get-color-from-palette($primary, 100) !important;
          &.mat-expanded {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }

          button.toggle-panel {
            background-color: transparent;
            box-shadow: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            min-width: 32px;
            padding: 0;
            &:hover {
              background: mat.get-color-from-palette($primary, 200) !important;
            }
            mat-icon {
              color: #283693;
              transition: 0.1s ease-in-out;
            }
          }

          .mat-expansion-indicator {
            position: absolute;
            width: 35px;
            text-align: center;
            right: 0;

            &:after {
              border-color: mat.get-color-from-palette($primary, 500);
            }
          }

          mat-panel-title {
            margin-right: 0;
            width: 100%;
            min-height: 36px;
            align-items: center;
            padding: 12.5px 15px;
            anms-value-stream-row {
              width: 100%;
            }
            .lane {
              border-top-right-radius: 4px !important;
              border-top-left-radius: 4px !important;
              position: relative;

              .lane-title-box {
                border: none !important;
                background: transparent !important;

                .lane-title,
                .lane-title a {
                  font-size: 16px !important;
                  font-weight: 500;
                  color: mat.get-color-from-palette($primary);
                }
              }

              .lane-holder {
                border: none !important;
                background: transparent !important;
                color: #283693;
                font-size: 14px;
                line-height: 17px;
                padding-left: 36px;
                margin-top: 5px;
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &:not(.mat-expanded) {
          .toggle-panel {
            background: mat.get-color-from-palette($primary, 200) !important;
            mat-icon {
              transform: rotate(180deg);
            }
          }
        }
      }

      .mat-expansion-panel-body {
        padding: 0;

        .drop-zone-list {
          margin: 0 !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          padding: 15px !important;
          max-height: 380px;
          overflow-y: auto;
          box-sizing: border-box;
          overflow-x: hidden;

          a.remove-card {
            position: absolute;
            z-index: 999;
            right: 15px;
            top: 15px;
            color: #666;
            cursor: pointer;
          }
        }
        .drop-zone-list::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }
  }

  /*Kanban: hide progress bar in funnel*/
  .Funnel,
  .Reviewing,
  .Analyzing {
    .progress {
      display: none;
    }
  }
  .art-kanban .kanban {
    height: 100% !important;
    overflow-y: auto;
  }
  /* anms-artdashboard mat-sidenav-content.mat-sidenav-content {
    .page-content {
    }
  }*/
  anms-program-kanban-dashboard {
    display: flex;
    flex-direction: column;
    anms-kanban-column {
      min-height: calc(100vh - 228px);
    }
  }
  anms-droppable {
    display: flex;
    flex-direction: column;
    height: 100%;
    .cdk-drop-list {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .collapsed .column-title {
    padding: 6px 6px;
  }
  .titleSticky {
    .col-item {
      &.collapsed {
        position: sticky;
        top: -1.5rem;
        .card-top {
          .collapse-column {
            width: 26px;
          }
          &:after {
            display: none;
          }
        }
      }
    }
    .card-top {
      position: sticky;
      width: 270px;
      top: -3px;
      z-index: 90;
      &:after {
        content: '';
        height: 2px;
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 99999;
        background: mat.get-color-from-palette($primary, 700);
        box-shadow: 0px 2px 3px mat.get-color-from-palette($primary, 500);
      }
    }
  }
  .compact-features-total {
    display: none !important;
  }
  .compactView {
    mat-card {
      .card-header {
        .card-icon {
          display: none;
        }
        margin-bottom: 0 !important;
        .compact-features-total {
          display: block !important;
        }
        .updated {
          display: none !important;
        }
      }
      .card-body {
        display: none;
      }
      .card-bottom {
        margin-top: 0 !important;
      }
    }
  }
  /*kanban ART temporary style to show different DoDs (until DoD is dynamic)*/
  .dashboard {
    .droppable-wrapper {
      .cdk-drop-list {
        anms-kanban-item-container {
          &:nth-child(3n + 1) {
            anms-feature-card {
              .dod {
                &.pending {
                  display: none !important;
                }
                &.ready {
                  display: none !important;
                }
              }
            }
          }
          &:nth-child(3n + 2) {
            anms-feature-card {
              .dod {
                &.undefined {
                  display: none !important;
                }
                &.ready {
                  display: none !important;
                }
              }
            }
          }
          &:nth-child(3n + 3) {
            anms-feature-card {
              .dod {
                &.undefined {
                  display: none !important;
                }
                &.pending {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  /*costs*/
  .budget {
    .mat-progress-bar {
      border-radius: 20px;
      .mat-progress-bar-background,
      .mat-progress-bar-buffer {
        background-color: #d1d1d1;
      }
      &.spent {
        .mat-progress-bar-fill::after {
          background-color: mat.get-color-from-palette($accent, 400);
        }
      }
      &.remaining {
        .mat-progress-bar-fill::after {
          background-color: #6d9e57;
        }
      }
    }
  }

  /*forms*/
  .cdk-overlay-dark-backdrop {
    background-color: #090c2258;
  }
  .popover-content {
    max-width: 250px;
    color: #fff;
    background: #333333d9 !important;
    padding: 10px;
    mat-card-content {
      font-size: 12px;
      line-height: 14px;
      color: #fff !important;

      p {
        color: #fff !important;
      }
    }
  }
  anms-overlaycomponent {
    // overflow: auto;
    // ---webkit-overflow-scrolling: touch;

    // position: relative;
    width: 100%;
    // position: fixed;
    // left: 12.5vw;
    // width: 75vw;
    // height: auto;
    // top: 90px;
    // overflow: hidden !important;
    border-radius: 4px;
    box-shadow: 2px 4px 7px rgb(0 0 0 / 18%);
    // padding: 30px;
    padding-bottom: 0;
    background: mat.get-color-from-palette($background, dialog);

    padding: 30px 3px 3px 3px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      padding: 30px 4px 4px 4px;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
      padding: 30px;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding: 30px;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
      padding: 30px;
    }

    .close-form {
      font-size: 22px;
      position: absolute;
      right: 15px;
      top: -20px;
      padding: 10px;

      z-index: 500;
      color: mat.get-color-from-palette($foreground, secondary-text);
      opacity: 0.65;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }

    .overlay {
      position: relative;
      // width: 100% !important;
      // display: flex;
      // overflow: hidden;
      // height: 100%;

      /* .default-form {
        &.update-form {
          .form-fields-inner {
            // height: auto;
          }
        }
      }*/
      .form-fields-inner {
        height: 100%;

        // flex: 1 0 40px;
        // overflow: hidden;
        //  overflow-x: hidden;
        // overflow-y: hidden;
        // overflow-x: hidden;
        margin-bottom: 15px;
        padding-bottom: 20px;
        > div {
          &::-webkit-scrollbar {
            width: 8px;
          }
        }
        > .col-md-4 {
          overflow-y: auto;
        }
        > .col-md-8 {
          overflow-y: auto;
          box-sizing: border-box;
          //margin-right:0px;
          // max-height: 60vh;
          &::-webkit-scrollbar-track {
            box-shadow: none;
          }
        }
      }

      .mat-input-element {
        color: mat.get-color-from-palette($foreground, text);
        @media (max-width: map-get($grid-breakpoints, lg)) {
          font-size: 16px;
        }
      }
      .gradient {
        .main-wrapper {
          height: 100%;
          display: flex;
          flex-direction: row;

          .row {
            margin: 0 auto !important;
            width: 100%;
          }
          .col {
            padding: 0 !important;
          }

          .children-wrapper {
            margin-top: 0px;
            margin-right: 10px;

            .children-main {
              flex-direction: revert;
              justify-content: space-between;
              background: mat.get-color-from-palette($accent, 200);
              padding: 0 10px !important;
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
              margin-bottom: 0px;

              h3 {
                color: mat.get-color-from-palette($primary);
                // font: 400 14px/32px Roboto;
                font-weight: 400;
                font-size: 14px;
                line-height: 32px;
                margin-bottom: 0px;
              }
              .btn-new,
              .btn-new span {
                color: mat.get-color-from-palette($primary);
                font-size: 20px;
              }
              .btn-new {
                display: flex;
                text-transform: uppercase;
                cursor: pointer;
                align-items: center;
              }
            }
            .key-results-main {
              flex-direction: revert;
              justify-content: space-between;
              background: mat.get-color-from-palette($accent, 100);
              padding: 0 10px !important;
              margin-top: 1rem;

              margin-bottom: 0px;

              h4 {
                color: mat.get-color-from-palette($primary);
                // font: 500 14px/32px Roboto;
                font-weight: 500;
                font-size: 14px;
                line-height: 32px;
                margin-bottom: 0px;
                padding-left: 24px;
              }
              .btn-new,
              .btn-new span {
                color: mat.get-color-from-palette($primary);
                font-size: 20px;
              }
              .btn-new {
                display: flex;
                text-transform: uppercase;
                cursor: pointer;
                align-items: center;
              }
            }
            .default-form {
              height: auto !important;
              mat-form-field {
                .mat-input-element {
                  color: mat.get-color-from-palette($foreground, text);
                  @media (max-width: map-get($grid-breakpoints, lg)) {
                    font-size: 16px;
                  }
                }
                .mat-form-field-flex {
                  border: solid 2px transparent; // Ensure the border is present, but transparent
                  border-radius: 4px; // Keep your existing border-radius
                }
                &.mat-focused {
                  .mat-form-field-flex {
                    border: solid 2px mat.get-color-from-palette($primary) !important;
                    border-radius: 4px !important;
                  }
                  .mat-form-field-underline {
                    display: none !important;
                  }
                }
                .mat-form-field-underline {
                  transform: none;
                }
              }
            }

            .input-wrapper {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              > mat-form-field {
                //border: solid 2px mat-color($primary, 500);
                border-top-left-radius: 4px;
                border-right: none;
                border-bottom-left-radius: 4px;
              }
              > span {
                display: flex;
                align-items: center;
                padding-right: 5px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                //border: solid 2px mat-color($primary, 500);
                border-left: none;
              }
              //.mat-form-field {
              //  width: 100%;
              //  margin: 5px auto !important;
              //  padding-right: 0 !important;
              //  .mat-form-field-wrapper {
              //    padding-bottom: 0;
              //  }
              //
              //}
              button {
                border-radius: 40px;
                padding: 0 !important;
                min-width: unset;
                width: 26px;
                height: 26px;
                margin-right: 2px;
                line-height: 1;
                background: transparent;
                span {
                  color: mat.get-color-from-palette($primary);
                  transition: 0.2s ease-in-out;
                  font-size: 18px;
                }
              }
            }
            .children-new {
              //max-height: 20vh;
              //overflow: auto;
              padding-right: 0px;
            }
            .children-list {
              margin-top: 0;
              border-top: none;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              padding: 0;
              margin-bottom: 15px;
              p {
                margin-bottom: 0;
                padding: 5px 10px;
                border-bottom: solid 1px #e4e4e4;
                //max-height: 50px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;
                align-items: center;
                strong,
                a {
                  color: #00000051;
                  border-bottom: none;
                  font-weight: 600;
                }
                a {
                  color: mat.get-color-from-palette($primary);
                  border-bottom: none;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  margin-right: 5px;
                  cursor: pointer;
                }
                .name {
                  flex: 2;
                  min-width: 0;
                  display: flex;
                  line-height: 15px;
                  span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-height: 16px;
                    display: block;
                    // vertical-align: middle;
                    overflow: hidden;
                  }
                }
                .section-2 {
                  flex: 1;
                  min-width: 0;
                  display: flex;
                  gap: 0.5rem;
                  justify-content: flex-end;
                  align-items: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .state {
                  flex: 1;
                  padding-inline: 0.5rem;
                  border-radius: 3px;
                  text-align: center;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .team {
                  flex: 2;
                  display: flex;
                  max-width: 65%;
                  form {
                    width: 100%;
                    .mat-form-field-label-wrapper {
                      top: 3px;
                      display: block;
                      height: 20px;
                      padding-top: 0 !important;
                      overflow: visible;
                      > label {
                        position: static;
                        height: 20px;
                        line-height: 23px;
                        display: flex;
                        padding: 3px;
                      }
                    }
                    mat-select {
                      &.mat-select-empty {
                        background-color: rgb(0 0 0 / 2%);
                        padding: 3px;
                      }
                      &:not(.mat-select-empty) {
                        span {
                          color: mat.get-color-from-palette(
                            $primary
                          ) !important;
                          text-decoration: underline;
                        }
                        .mat-select-arrow {
                          color: mat.get-color-from-palette(
                            $primary
                          ) !important;
                        }
                      }
                      .mat-select-arrow-wrapper {
                        display: block;
                      }
                    }
                  }
                  mat-form-field {
                    width: 100%;
                    margin-bottom: 0;
                    padding-right: 0 !important;
                    .mat-form-field-flex {
                      padding-top: 0 !important;
                    }
                    .mat-form-field-wrapper {
                      padding-bottom: 0 !important;
                    }
                    .mat-form-field-underline {
                      display: none;
                    }
                    .mat-form-field-label-wrapper {
                      .mat-form-field-label {
                        text-align: left !important;
                      }
                    }
                  }
                }
              }
            }
            .mat-form-field .mat-form-field-flex {
              transition: 0.2s ease-in-out;
            }
            .mat-form-field-type-mat-input:not(.mat-focused)
              .mat-form-field-flex {
              //border: solid 2px mat-color($primary, 500);
              transition: 0.2s ease-in-out;
            }
          }
          .form-fields {
            // flex: 1 0 50px;
            display: flex;

            /* Apply overflow-y:auto only for mobile and tablet devices */
            @media (max-width: map-get($grid-breakpoints, lg)) {
              overflow-y: auto;
              ---webkit-overflow-scrolling: touch;
            }
            flex-direction: column;
            height: 78vh;
            //height: 100%;
            // margin-bottom: 3.5rem;
            margin-top: 1.5rem;
            flex-grow: 1;
            // .mat-form-field-appearance-fill {
            // .mat-form-field-flex {
            //background-color: rgb(0 0 0 / 2%);
            // }
            //}

            > .row {
              width: 100%;
              margin: 0 auto;
              flex: 0 0 40px;
              .col {
                padding: 0;
              }
            }
            .fr-toolbar .fr-btn-grp {
              margin: 0;
            }
            .fr-box.fr-basic.fr-top {
              span.fr-placeholder {
                font-size: 12px !important;
                line-height: 15px !important;
              }
              > div {
                background: transparent;
                border: none;
                border-radius: 0;
                button {
                  height: 20px;
                  width: 20px;
                  svg {
                    width: 18px;
                    height: 18px;
                    margin: 0;
                  }
                }
                .fr-view.fr-element {
                  padding: 10px 0;
                  font-size: 16px;
                  // font-family: Roboto, 'Helvetica Neue', sans-serif;
                }
              }
              .fr-toolbar.fr-desktop.fr-top {
                border-top: solid 1px;
                border-bottom: solid 1px;
                border-color: #cecece;
              }
              .fr-second-toolbar {
                display: none !important;
              }
            }
            .mat-form-field-flex {
              border-radius: 4px 4px 0 0;
              padding: 0.55em 0.55em 0 0.55em;
            }
            .description {
              font-size: 12px;
              color: #00000051;
              letter-spacing: 0.29px;
              font-weight: 400;
            }
          }
          .default-form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;

            h2 {
              font-weight: 500 !important;
              color: mat.get-color-from-palette($primary, 500);
              margin-bottom: 0.5rem;
              font-size: 16px !important;
              letter-spacing: 0.3px;
              line-height: 18px;
            }
            .item-meta {
              display: flex;
              flex-direction: column;
              letter-spacing: 0.3px;
              margin-bottom: 0;
              p {
                margin-inline-end: 7px;
                color: mat.get-color-from-palette($foreground, text);
                font-size: 12px;
                margin-block-end: 0;
                font-weight: unset;
                text-rendering: geometricPrecision;
                letter-spacing: 0.5px;
                line-height: 14px;
              }
              &.item-meta-bottom {
                margin-top: 10px;
              }
            }

            .default-full-width {
              width: 100%;
              &.description-field {
                .mat-form-field-infix {
                  min-height: 205px;
                }
              }
              .mat-form-field-label-wrapper {
                //padding: 1em 0;
                //top: -23px;
                display: flex;
                align-items: flex-start;
                min-height: 30px;
              }
              &.ng-valid,
              &.ng-touched {
                .mat-form-field-label-wrapper {
                  top: -0.84375em;
                  padding-top: 0.84375em;
                }
                &.mat-form-field-invalid {
                  mat-label {
                    font-size: 11px;
                  }
                }
                .mat-form-field-label-wrapper {
                  //padding: 1em 0;
                  //top: -23px;
                  display: flex;
                  align-items: flex-start;
                  min-height: 12px;
                }
                &.ng-valid,
                &.ng-touched {
                  .mat-form-field-label-wrapper {
                    top: -0.84375em;
                    padding-top: 0.84375em;
                  }
                }
              }
              &.acceptance-criteria-field {
                &.mat-form-field-invalid {
                  mat-label {
                    font-size: 11px;
                  }
                  .mat-form-field-label-wrapper {
                    top: -19px;
                  }
                }
                &.ng-untouched:not(.ng-valid) {
                  .mat-form-field-label-wrapper {
                    top: -23px;
                  }
                }
              }
            }
            mat-divider {
              margin-bottom: 15px;
            }
            .mat-form-field {
              margin-bottom: 15px;
              padding-right: 10px !important;
              box-sizing: border-box !important;
              mat-label {
                //padding: 0 10px !important;
                border: none;
                width: 100%;
                box-sizing: inherit;
                display: block;
              }
              .textarea-form {
                min-height: 70px;
              }
              .desc-info {
                position: absolute;
                right: 0;
                top: -15px;
              }
            }
            &.update-form {
              .col-md-3 {
                .mat-form-field-subscript-wrapper {
                  padding: 0;
                }
              }
            }
          }
          .form-actions {
            // flex: 0 0 65px;
            display: flex;
            justify-content: space-between;
            //position: absolute;
            z-index: 8888;
            // bottom: -45px;
            height: 55px;
            //background: #fafafa;
            // width: 70vw;
            left: 0;
            padding: 10px 10px;

            button {
              border-radius: 40px;
              text-transform: uppercase;
              font-weight: 400;
              span,
              fa-icon {
                margin-right: 5px;
              }
            }
            .action-col {
              &.primary {
                button {
                  margin-left: 15px;
                  // width: 135px;
                  &.btn-save {
                    span {
                      color: mat.get-color-from-palette($foreground, button);
                    }
                    &:hover {
                      background-color: mat.get-color-from-palette(
                        $primary,
                        600
                      ) !important;
                    }
                  }
                  &.btn-delete {
                    color: mat.get-color-from-palette($primary);
                    border: solid 1px;
                    span {
                      color: mat.get-color-from-palette($primary);
                    }
                    &:hover {
                      background-color: transparent !important;
                      span {
                        color: mat.get-color-from-palette($primary, 600);
                      }
                    }
                  }
                }
              }
              &.secondary {
                button {
                  background: transparent;
                  color: mat.get-color-from-palette(
                    $foreground,
                    secondary-text
                  );
                  opacity: 0.65;
                  transition: 0.2s ease-in-out;
                  span {
                    color: mat.get-color-from-palette(
                      $foreground,
                      secondary-text
                    );
                  }
                  &:hover {
                    opacity: 1;
                  }
                }
              }
            }
          }
          /* Mobile-specific styles */
          @media (max-width: map-get($grid-breakpoints, md)) {
            .form-actions {
              position: fixed;
              bottom: 0;
              left: 0;
              width: 100%;
              background: #fafafa;
              padding: 10px;
              box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
  anms-user-story-form,
  anms-enabler-story-form {
    .form-fields-inner {
      .description-field {
        .mat-form-field-infix {
          min-height: 75px !important;
        }
      }
      .acceptance-criteria-field {
        .mat-form-field-infix {
          min-height: 135px !important;
          textarea {
            min-height: 115px !important;
          }
        }
      }
    }
  }
  anms-solution-internal-compact-card {
    .solution-icon {
      width: 18px !important;
      height: 18px !important;
      margin-right: 0 !important;
      align-items: flex-start !important;
      .mat-icon {
        padding: 3px;
      }
    }
  }
  anms-value-stream-portfolio-allocations {
    mat-select:not(.mat-select-empty) ~ span label {
      display: none !important;
    }
    .mat-form-field-underline {
      display: none !important;
    }
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
      .mat-form-field-flex {
        padding-top: 0 !important;
      }
      .mat-form-field-infix {
        padding: 0 !important;
        border-top: 0 !important;
        .mat-form-field-label-wrapper {
          position: static;
        }
        .mat-select-arrow-wrapper {
          transform: translateY(0%);
        }
      }
    }
  }
  anms-epic-overlay-form,
  anms-feature-form {
    .children-wrapper {
      mat-select:not(.mat-select-empty) ~ span label {
        display: none !important;
      }
    }
    .team {
      .mat-form-field-infix {
        border-top: 0;
        text-align: right !important;
      }
    }
  }
  anms-epic-overlay-form {
    .description-field {
      &.mat-form-field-invalid {
        .mat-form-field-label {
          top: 7px;
          margin-top: -0.5em;
        }
      }
    }
  }
  anms-art {
    .solution-card-wrapper {
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }
  }
  /*
  anms-solutions-grid {
    .filter-wrapper {
      #filter {
        mat-form-field,
        .mat-form-field {
          padding-inline: 0;
          > div {
            padding-bottom: 0 !important;
            .mat-form-field-infix {
              padding: 0.5em 0 0.5em 0;
            }
          }
        }
      }
    }
    .mat-tab-header {
      border-bottom: none !important;
      margin-bottom: 15px;
    }
    .mat-tab-label {
      background: #e4e4e478;
      border-radius: 4px;
      height: 35px;
      font-weight: 400;
      min-width: 85px;
      margin-left: 10px;
      opacity: 1 !important;
      &.mat-tab-label-active {
        color: mat.get-color-from-palette($primary, 500) !important;
        background: mat.get-color-from-palette($primary, 100) !important;
        font-weight: 600;
      }
    }
    .mat-tab-body-content {
      margin-bottom: 20px;
      padding-bottom: 20px;
      overflow: auto;
      height: fit-content;
    }
  }
    */
  .dashboard-table-main-wrapper {
    .tables-wrapper {
      max-width: 100%;
      overflow-x: auto;
    }
    .mat-sort-header-arrow {
      opacity: 0.5 !important;
      transform: none !important;
    }
    .mat-paginator {
      background: transparent;
    }
    th,
    td {
      padding: 10px !important;
    }
    .mat-column-id,
    .mat-column-kanbanId {
      max-width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .mat-column-name {
      max-width: 300px;
    }
    .mat-column-description {
      max-width: 350px;
      font-size: 12px;
      line-height: 14px;
    }
    table {
      width: 100%;
      background: none !important;
      box-shadow: none !important;
      border-collapse: separate;
      border-spacing: 0 10px !important;
      tr.mat-header-row {
        height: 36px;
        th {
          text-transform: uppercase;
          border-width: 2px;
          border-color: #73737396;
          position: relative;
          top: 10px;

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
      tr.mat-row {
        border-radius: 4px !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
        position: relative;
        td {
          border-bottom: none !important;
          background: mat.get-color-from-palette($background, card);
          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .edit-row {
            position: relative;
            right: 0px;
            top: 5px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            color: mat.get-color-from-palette($foreground, base);
            &:hover {
              color: mat.get-color-from-palette($primary, A400);
            }
          }
        }
      }
    }
    .table-top {
      .main-heading {
        font-weight: 500;
        color: mat.get-color-from-palette($foreground, text);
      }
      .pagination-wrapper {
        .mat-paginator {
          background: transparent !important;
          .mat-form-field-infix {
            background: #b9b9b919;
            display: flex;
            justify-content: space-between;
            border-radius: 4px;
            border: solid 1px #00000024;
            transition: 0.2s ease-in-out;
            padding-inline: 10px;
            height: 40px;
            align-items: center;
          }
        }
      }

      .search-bar-wrapper {
        background: #b9b9b919;
        display: flex;
        justify-content: space-between;
        padding: 4px 16px;
        margin-bottom: 16px;
        border-radius: 40px;
        border: solid 1px #00000024;
        transition: 0.2s ease-in-out;
        // width: 25vw;
        height: 37px;
        margin-right: 25px;
        mat-form-field {
          width: 100%;
        }
        &:focus,
        &:active,
        &:focus-within {
          border-color: mat.get-color-from-palette($accent, 400);
          box-shadow: 1px 2px 4px mat.get-color-from-palette($accent, 100);
          fa-icon {
            color: mat.get-color-from-palette($accent, 400);
            opacity: 0.85;
          }
        }
        fa-icon {
          color: #00000024;
          transition: 0.2s ease-in-out;
        }
      }
      @media (max-width: map-get($grid-breakpoints, lg)) {
        .search-bar-wrapper {
          width: 100%;
        }
      }
      @media (min-width: map-get($grid-breakpoints, lg)) {
        .search-bar-wrapper {
          width: 25vw;
        }
      }
    }
  }

  //TODO move styling to corresponding component when mixing&theme variables is ready to use in components
}

/* Style for the toggle button with icons */
.toggle-button {
  position: fixed;
  top: 70px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 1;
}
.push-content {
  margin-right: 480px; /* Adjust the margin to match the width of your help panel */
}

.toggle-button:hover {
  background-color: #0056b3;
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .toggle-button {
    top: 100px;
  }
}

// Media query for small (sm) and medium (md) devices
@media (max-width: map-get($grid-breakpoints, md)) {
  .w-md-100 {
    width: 100%;
    // Add additional styles for small and medium devices if needed
    // For example:
    // max-width: 500px;
    // margin: 0 auto; // Center the element
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}
