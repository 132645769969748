@use '@angular/material' as mat;

@mixin anms-neo-button-component-theme($theme) {
  // Get the primary color from the theme map
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  // Lighten and darken the primary color using Angular Material's theming functions
  /* $shadow-light: mat.get-color-from-palette(
    $primary,
    200
  ); // Light shade of primary
  $shadow-dark: mat.get-color-from-palette(
    $primary,
    700
  ); // Dark shade of primary
  */

  // Get specific shades for neomorphic effect
  $base-color: mat.get-color-from-palette($primary, 500);
  $shadow-light: mat.get-color-from-palette($primary, 200);
  $shadow-dark: mat.get-color-from-palette($primary, 800);
  $icon-color: mat.get-color-from-palette($accent, 100);

  .neo-button {
    background: $base-color;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;

    // Neomorphic shadow effect
    //box-shadow: 6px 6px 12px rgba($shadow-dark, 0.3);

    &:hover {
      //box-shadow: 4px 4px 8px rgba($shadow-dark, 0.2);
    }

    &:active {
      //box-shadow: inset -3px -3px 6px rgba($shadow-light, 0.4),
      //  inset 3px 3px 6px rgba($shadow-dark, 0.2);
    }

    .material-icons {
      margin-right: 10px;
      color: $icon-color;
      transition: transform 0.2s ease;
    }

    // Add subtle text shadow for better contrast
    span {
      text-shadow: 0 2px 2px rgba($shadow-dark, 0.2);
    }
  }
}
