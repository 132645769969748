@use '@angular/material' as mat;

@mixin anms-team-item-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  .team-art-item {
    background: mat.get-color-from-palette($background, card);

    span.team-picture {
      color: mat.get-color-from-palette($primary, 700);
    }
    img.team-picture {
      color: mat.get-color-from-palette($primary, 700);
      background: mat.get-color-from-palette($background, card);
    }
    // a.team-link {
    // color: mat.get-color-from-palette($primary, 700);
    //}
  }
}
