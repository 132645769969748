@use '@angular/material' as mat;

@mixin anms-overlay-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .overlay.background {
    height: 100%;
    .gradient {
      &::before {
        background: linear-gradient(
          to bottom,
          mat.get-color-from-palette($background, background, 0) 0%,
          mat.get-color-from-palette($background, background, 1) 100%
        );
      }
    }
  }
}
