@use '@angular/material' as mat;

@mixin anms-portfolio-canvas-component-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);

  .canvas-wrapper {
    border-radius: 4px;

    margin-bottom: 40px;
    // color: mat.get-color-from-palette($primary); // Use primary color for text
    background: mat.get-color-from-palette($background, focused-button);
    // Dynamic background color
    border: solid 1px mat.get-color-from-palette($primary, 200); // Dynamic border color

    .vs-title {
      font-weight: 500;
      text-align: center;
      //  padding: 5px;
      padding-top: 4px;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      // border-bottom: solid 1px #fafafa;
      height: 32px;
      background: mat.get-color-from-palette(
        $primary,
        100
      ); // Dynamic title background color
      color: mat.get-color-from-palette($primary, 500); // Dynamic accent color
      border-bottom: solid 1px mat.get-color-from-palette($primary, 50); // Dynamic border color for the title
    }

    .canvas-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;

      .vs-columns {
        width: 100%;
        display: flex;

        > div {
          background-color: mat.get-color-from-palette($background, default);
          // Dynamic column background color
          border: solid 1px mat.get-color-from-palette($primary, 200); // Dynamic border color
          text-align: center;
          height: 36px;
          font-size: 12px;
          line-height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.89px;
          color: mat.get-color-from-palette($primary, 500);
        }

        &.grid {
          > div {
            //width: 14vw;
            //
            width: 100%;
          }
        }

        &.thirds {
          > div {
            width: 33.33%; // Static width for thirds
          }
        }
        aa &.half {
          > div {
            width: 50%; // Static width for halves
          }
        }
      }

      .vs-content {
        width: 100%;
        display: flex;

        > div {
          padding: 10px;
        }

        &.grid {
          > div {
            width: 15%; // Static width for grid items
            outline: solid 1px mat.get-color-from-palette($primary, 200); // Dynamic outline color for grid items
          }
        }

        &.thirds {
          > div {
            width: 33.33%; // Static width for thirds
            outline: solid 1px mat.get-color-from-palette($primary, 200); // Dynamic outline color for content divs
          }
        }

        &.half {
          > div {
            width: 50%; // Static width for halves
            outline: solid 1px mat.get-color-from-palette($primary, 200); // Dynamic outline color for content divs
          }
        }
      }
    }
  }
}
