@use '@angular/material' as mat;
@import 'styles-variables';

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';

@import 'styles-reset';

@import './themes/kvasar-palette';

@import './themes/default-theme';
@import './themes/light-theme';
@import './themes/black-theme';
@import './themes/nature-theme';
@import './themes/kvasar-theme';

@import 'styles-reset.scss-theme';

@import './app/app/app.component.scss-theme';
@import './app/shared/big-input/big-input/big-input.component.scss-theme';
@import './app/kanban/kanban-column/kanban-column.component.scss-theme.scss';
@import './app/kanban/kanban-column/title-card/title-card.component.scss-theme';
@import './app/overlay-forms/overlay/overlaycomponent/overlaycomponent.component-scss-theme';

@import './app/features/about/about/about.component.scss-theme.scss';

@import './app/features/examples/notifications/components/notifications.component.scss-theme';
@import './app/features/examples/stock-market/components/stock-market-container.component.scss-theme';
@import './app/features/examples/theming/parent/parent.component.scss-theme';
@import './app/features/examples/theming/child/child.component.scss-theme';
@import './app/features/examples/examples/examples.component.scss-theme';
//@import './app/features/solutions/epic/epic.component.scss-theme.scss';
@import './app/shared/solution-forms/solutions-form/solutions-form.component.scss-theme';
@import './app/shared/requirement-forms/epic/epic-overlay-form.component.scss-theme.scss';
@import './app/features/organization/organization-dashboard/strategic-themes-list/budget-container/budget-container.component.scss-theme.scss';
@import './app/features/organization/organization-dashboard/strategic-themes-list/strategic-themes-list.component.scss-theme.scss';
@import './app/shared/team-selector/teams-select/teams-select.component.scss-theme';
@import './app/shared/pie-chart/pie-chart.component.scss-theme.scss';
@import './app/shared/input-editor/input-editor.component.scss-theme.scss';

@import './app/features/portfolio/portfolio-safe-dashboard/portfolio-canvas/portfolio-canvas.component.scss-theme.scss';
@import './app/shared/solutions-grid/solutions-grid.component.scss-theme.scss';
@import './app/features/organization/organization-dashboard/value-stream-catalog/value-stream-detail-container/value-stream-detail-container.component.scss-theme.scss';
@import './app/features/arts/artdashboard/program-kanban/teams-art-list/team-item/team-item.component.scss-theme.scss';
@import './app/features/arts/artdashboard/program-kanban/teams-art-list/teams-art-list.component.scss-theme.scss';
@import './app/shared/dashboard/dashboard.component.scss-theme.scss';
@import './app/shared/dashboard/main-heading/main-heading.component.scss-theme.scss';
@import './app/app/neo-button/neo-button.component.scss-theme.scss';
@import './app/shared/requirement-forms/epic/feature-row/feature-row.component.scss-theme.scss';

@mixin custom-components-theme($theme) {
  @include anms-styles-reset-theme($theme);
  @include anms-app-component-theme($theme);
  @include anms-neo-button-component-theme($theme);
  //  @include anms-dashboard-component-theme($theme);
  @include anms-about-component-theme($theme);
  @include anms-big-input-component-theme($theme);
  @include anms-notifications-component-theme($theme);
  //@include anms-todos-container-component-theme($theme);
  @include anms-stock-market-container-component-theme($theme);
  @include anms-parent-component-theme($theme);
  @include anms-child-component-theme($theme);
  @include anms-examples-component-theme($theme);
  @include title-card-component-theme($theme);
  @include anms-kanban-column-container-component-theme($theme);
  @include anms-overlay-component-theme($theme);
  @include anms-solutions-form-overlay-component-theme($theme);
  @include anms-epic-form-overlay-component-theme($theme);
  @include anms-feature-row-component-theme($theme);
  @include anms-budget-container-component-theme($theme);
  @include anms-strategic-themes-list-component-theme($theme);
  // @include anms-epic-form-overlay-component-theme($theme);

  // @include anms-backlog-component-theme($theme);
  @include anms-teams-select($theme);
  @include anms-pie-chart-component-theme($theme);
  @include anms-portfolio-canvas-component-theme($theme);
  @include anms-input-editor-component-theme($theme);
  @include anms-solutions-grid-component-theme($theme);
  @include anms-team-item-theme($theme);
  @include anms-teams-art-list-theme($theme);
  // @include anms-feature-component-theme($theme)
  @include anms-value-stream-detail-container($theme);
  @include anms-main-heading-component-theme($theme);
}

@import './styles-common.scss';

@import './styles-themes.scss';

// Default values for the CSS variables
:root {
  --topNavigationHeight: 317px;
  --bannerHeight: 39px;
}

// Function to generate media queries from the breakpoints map
@function breakpoint-min($key) {
  @return map-get($grid-breakpoints, $key);
}

// Set different values for the variables at each breakpoint
// NO efect for md
@media (min-width: #{breakpoint-min(md)}) {
  :root {
    //--topNavigationHeight: 315px;
    //--bannerHeight: 42px;
    --topNavigationHeight: 64px;
    --bannerHeight: 0px;
  }
}

@media (min-width: #{breakpoint-min(lg)}) {
  :root {
    //--topNavigationHeight: 259px;
    //--bannerHeight: 52.8px;
    --topNavigationHeight: 161px;
    --bannerHeight: 0px;
  }
}

@media (min-width: #{breakpoint-min(xl)}) {
  :root {
    //--topNavigationHeight: 213.2px;
    //--bannerHeight: 84.2px;
    --topNavigationHeight: 159px;
    --bannerHeight: 0px;
  }
}

// FROALA

.fr-view,
.fr-element {
  font-size: 16px !important;
}

.green-progress-bar .mat-progress-bar-fill::after {
  background-color: #4caf50;
}

.custom-dialog-account-menu {
  position: absolute !important;
  right: 0;
  top: 55px;
  .mat-dialog-container {
    border-top-right-radius: 0 !important;
  }
}

.account-menu-backdrop {
  background-color: transparent;
}

.kpi-form-container {
  overflow-x: hidden;
  .dropdown-list {
    position: static !important;
  }
}
.ql-container.ql-snow {
  height: auto; /* Default height */
}
@media (max-width: 768px) {
  /* Adjust for mobile screens */
  .ql-container.ql-snow {
    max-height: 30vh; /* 30% of the viewport height */
    overflow-y: auto; /* Enable scrolling if content exceeds height */
  }
}
.ql-toolbar.ql-snow .ql-formats {
  margin-top: 1.5em; /* Change this value as needed */
}
