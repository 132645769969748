@use '@angular/material' as mat;
@import '~@angular/material/theming';

@mixin anms-big-input-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  anms-big-input {
    .big-input {
      input {
        color: mat.get-color-from-palette($foreground, text);
        background-color: mat.get-color-from-palette($background, card);
      }

      &.has-focus {
        box-shadow: 0 0 15px 2px rgba(mat.get-color-from-palette($accent), 0.4),
          0 0 15px 2px rgba(mat.get-color-from-palette($accent), 0.4),
          0 0 15px 2px rgba(mat.get-color-from-palette($accent), 0.4);
      }
    }
  }
}
