@use '@angular/material' as mat;

@mixin anms-main-heading-component-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  h1.main-heading {
    color: mat.get-color-from-palette($primary);
  }
}
