@import '~@angular/material/theming';

@mixin anms-budget-container-component-theme($theme) {
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  .budget-container {
    background-color: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);
  }
  .item-wrapper {
    .button-revision {
      width: 100%;
      //display: flex;
      // align-items: center;
      //justify-content: space-between;
      cursor: pointer;
    }
    .value {
      font-size: 14px;
    }
    .material-icons {
      color: mat.get-color-from-palette($accent);
      // color: #03a9f4;
    }
  }
}
