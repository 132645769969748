@use '@angular/material' as mat;
@import '~@angular/material/theming';

@mixin anms-about-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  anms-about {
    .background {
      color: mat.get-color-from-palette($foreground, text);
      background-color: mat.get-color-from-palette($background, background);

      .gradient {
        &::before {
          background: linear-gradient(
            to bottom,
            mat.get-color-from-palette($background, background, 0) 0%,
            mat.get-color-from-palette($background, background, 1) 100%
          );
        }
      }
    }

    .follow-releases,
    .contributors {
      p {
        background-color: mat.get-color-from-palette($background, background);

        a {
          color: mat.get-color-from-palette($accent);

          &:hover {
            color: mat.get-color-from-palette($accent, darker);
          }
        }
      }
    }

    .contributors {
      img {
        border-color: mat.get-color-from-palette(
          $background,
          background
        ) !important;
      }
    }
  }
}
