@import '~@angular/material/theming';

@mixin anms-pie-chart-component-theme($theme) {
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  .chart-container {
    background-color: mat.get-color-from-palette($background, card);
  }
}
