@use '@angular/material' as mat;

@mixin anms-value-stream-detail-container($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .value-stream-detail {
    .mat-expansion-panel {
      // margin-bottom: 10px;
      .mat-expansion-panel-header {
        mat-panel-title {
          p {
            align-items: center;
            mat-icon {
              background-color: mat.get-color-from-palette($primary, 100);
              width: 36px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              svg {
                width: 18px;
                height: auto;
              }
            }
            .title {
              font-size: 15px;
              font-weight: 600;
              color: mat.get-color-from-palette($primary);
            }
            .mat-expansion-panel-header-description {
              font-size: 14px;
            }
          }
        }
        .panel-header-actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          // position: absolute;
          //right: 0;
          //height: 85px;
          // top: 0;
          .toggle-trigger-custom {
            color: mat.get-color-from-palette($foreground, secondary-text);
            font-size: 14px;
            border-radius: 17px;
            display: flex;
            padding: 5px 20px;
            background: #f4f4f4;
          }
          .edit-item {
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .mat-expansion-panel-header[aria-expanded='true'] {
        .toggle-trigger-custom {
          color: mat.get-color-from-palette($foreground, secondary-text);
          font-size: 14px;
          border: solid 1px;
          border-radius: 17px;
          display: flex;
          padding: 5px 20px;
          background: mat.get-color-from-palette($background, card);
        }
      }
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          margin-top: 32px;
          mat-card-content {
            .addBtnMinified {
              background-color: mat.get-color-from-palette($accent);
              width: 22px;
              height: 22px;
              padding: 0;
              border-radius: 18px;
              border: none;
              color: mat.get-color-from-palette($background, card);
              box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
              position: absolute;
              right: 0;
              top: 0;
            }
            .col-md-9 {
              .addBtnMinified {
                right: 25px;
              }
            }
            #triggers {
              .content {
                width: 95%;
                height: 64px;
                background: mat.get-color-from-palette($primary, 200);
                position: relative;
                margin-right: 3rem;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 2px;

                p {
                  margin-bottom: 0;
                  color: mat.get-color-from-palette($primary);
                  line-height: 1.2;
                }
              }
            }
            .section-title {
              color: mat.get-color-from-palette($foreground, secondary-text);
            }
          }
        }
      }
    }

    anms-solutions-grid-card {
      margin: 0 0 5px 0 !important;
      ::ng-deep .mat-card {
        width: 95% !important;
        height: 100%;
        margin-right: 5px !important;
        .mat-card-header {
          .mat-card-avatar {
            width: 16px !important;
            height: 16px !important;
            justify-content: flex-start;
          }
          .type-label {
            position: static !important;
            padding-left: 0 !important;
            text-align: left !important;
          }
        }
        .mat-card-content {
          .info {
            display: none !important;
          }
        }
        .mat-card-actions {
          .buttons {
            top: unset !important;
          }
        }
      }
    }
  }
  anms-supporting-value-stream {
    width: 100%;
  }
  .valuestream-description {
    display: block;
    width: 65vw;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 1310px) {
    .valuestream-description {
      display: block;
      width: 55vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 1000px) {
    .valuestream-description {
      display: block;
      width: 45vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 800px) {
    .valuestream-description {
      display: block;
      width: 30vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 500px) {
    .valuestream-description {
      display: block;
      width: 25vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .graphic-vs-wrapper {
    // padding: 40px;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 100;
  }

  @media screen and (max-width: 1400px) {
    .graphic-vs-wrapper {
      overflow: auto;
      overflow-y: auto;
    }
  }
  ::ng-deep .highlight {
    background: yellow;
  }
}
