@use '@angular/material' as mat;

@mixin anms-solutions-grid-component-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);

  .filter-wrapper {
    #filter {
      mat-form-field,
      .mat-form-field {
        padding-inline: 0;
        > div {
          padding-bottom: 0 !important;
          .mat-form-field-infix {
            padding: 0.5em 0 0.5em 0;
          }
        }
      }
    }
  }
  .mat-tab-header {
    border-bottom: none !important;
    margin-bottom: 15px;
  }
  .mat-tab-label {
    background: #e4e4e478;
    border-radius: 4px;
    height: 35px;
    font-weight: 400;
    min-width: 85px;
    margin-left: 10px;
    opacity: 1 !important;
    &.mat-tab-label-active {
      color: mat.get-color-from-palette($primary, 500) !important;
      background: mat.get-color-from-palette($primary, 100) !important;
      font-weight: 600;
    }
  }
  .mat-tab-body-content {
    margin-bottom: 20px;
    padding-bottom: 20px;
    overflow: auto;
    height: fit-content;
  }
}
