@import '~@angular/material/theming';

@mixin anms-feature-row-component-theme($theme) {
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  .state {
    // padding: 0.25rem 0.5rem;
    //border-radius: 0.25rem;
    color: mat.get-color-from-palette($accent);
    // font-size: 0.6rem;
    //font-weight: 500;
  }
}
